<template>
  <div
    id="order_detail"
    class="container"
    style="background-color: white;font-size: 18px;font-weight: 700;color: black;padding: 100px;height: 100%;"
  >
    <div
      class="row"
      style="margin-top: -80px;"
    >
      <div class="col-md-12 row">
        <div class="col-md-12 row">
          <table
            style="width: 100%;"
            width="100%"
          >
            <tr>
              <td width="50%"></td>
              <td
                style="text-align: right"
                width="50%"
              >
                <div class="float-right">
                  <!--                  <img :src="order.path_qr" width="35%">-->

                  <!--                  :size="size"-->
                  <qr-code
                    v-if="order && order.order_inv"
                    size="60"
                    style="margin: 10px auto;float: right"
                    :text="order.order_inv"
                  ></qr-code>
                  <br>
                  <b style="font-size: 18px;font-weight: 700;color: black;">{{ order.order_inv }}
                  &nbsp;&nbsp;</b>&nbsp;
                  <br>
                </div>
                <br><br>
              </td>
            </tr>
            <tr>
              <td width="50%"></td>
              <td
                style="text-align: right"
                width="50%"
              >
                <div class="float-lg-right">
                  <div v-for="(config,index) in configs">
                    <div v-if="config.check && config.check === true">
                      <input
                        v-model="config.check"
                        type="checkbox"
                        checked
                      >
                      <span style="color: #ff4d4d"> {{ config.name }}</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <table style="width: 100%">
          <tr>
            <td style="width: 50%;text-align: left">
              <b
                style="text-align: left;font-size: 18px;font-weight: 700;color: black;"
              >ชื่อและที่อยู่ผู้ส่ง
                <br></b>
              <b
                style="text-align: left;font-size: 18px;font-weight: 700;color: black;"
              >{{
                order.sender_name ? order.sender_name : 'whatyouwantshop'
              }} </b><br>
              <b
                style="text-align: left;font-size: 18px;font-weight: 700;color: black;"
              >{{
                order.sender_address ? order.sender_address : '89/321 มบ ทาวน์อเวนิว เมิร์จ รัตนาธิเบศร์ ม10  ซอยวัดมะเดื่อ ต บางรักใหญ่ อ บางบัวทอง จ นนทบุรี 11110 T.0956965451 *ได้รับสินค้าแล้วถ่ายรีวิวมาอวดกันบ้างนะครับ*'
              }} </b><br>
              <br>
            </td>
            <td style="width: 50%;text-align: left">
              <br>
            </td>
          </tr>
          <tr>
            <td style="width: 50%;text-align: left">
              <br>
            </td>
            <td style="width: 50%;text-align: left">
              <b style="text-align: left;font-size: 18px;font-weight: 700;color: black;"> &nbsp;
                &nbsp;ชื่อและที่อยู่ผู้รับ
                <br></b>
              <b style="text-align: left;font-size: 18px;font-weight: 700;color: black;"> &nbsp;
                &nbsp; &nbsp;
                &nbsp; &nbsp;{{ order.recipient_name ? order.recipient_name : '-' }} </b><br>
              <b
                v-if="order.recipient_address && order.recipient_address != 'null' && order.recipient_address != null"
                style="text-align: left;font-size: 18px;font-weight: 700;color: black;"
              >
                &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;{{ order.recipient_address ? order.recipient_address : '-' }}
              </b><br>

              <br>
            </td>
          </tr>
        </table>
      </div>

      <table style="width:50%">
        <tr style="width:100%">
          <td
            v-for="(order_detail,index) in print_envelope_order_details "
            style="text-align: center"
          >
            <div v-if="show === 'Y'">
              <div

                v-if="order_detail.pattern && order_detail.pattern.location_files"
                style="width: 100px"
              >
                <span
                  v-if="order_detail.pattern.type_link_add_files ===1 || order_detail.pattern.type_link_add_files === '1'"
                >
                  <v-img
                    v-if="order_detail.pattern && order_detail.pattern.location_files"
                    style="width: 100px;object-fit: contain;"
                    :src="order_detail.pattern.location_files"
                  ></v-img>

                </span>
                <span
                  v-if="order_detail.pattern.type_link_add_files !==1 && order_detail.pattern.type_link_add_files !== '1'"
                >
                  <br><br>
                  <!--                <a :href="order_detail.location_files" target="_blank">ไฟล์เพิ่มเติม</a>-->
                  <router-link
                    v-if="order_detail.pattern_img_product && order_detail.pattern_img_product.pattern_img"
                    target="_blank"
                    :to="order_detail.pattern_img_product.pattern_img"
                  >
                    ไฟล์เพิ่มเติม
                  </router-link><br> <br>
                </span>
              </div>
              <div
                v-if="!order_detail.pattern || !order_detail.pattern.location_files"
                style="width: 100px"
              >
                <v-img
                  v-if="order_detail.pattern_img_product && order_detail.pattern_img_product.pattern_img"
                  style="width: 100px;object-fit: contain;
"
                  :src="order_detail.pattern_img_product.pattern_img"
                ></v-img>
              </div>
              <div
                class="card-text"
                style="font-size: 16px;font-weight: 700;color: black;width: 100px"
              >
                รุ่น:
                {{
                  order_detail.product && order_detail.product.phone_model ? order_detail.product.phone_model.phone_model_name : '-'
                }}
              </div>
            </div>
          </td>
        </tr>
        <!--        <tr style="width:100%">-->
        <!--          <td style="text-align: center">-->
        <!--            @if($order_detail->location_files)-->
        <!--              @if($order_detail->type_link_add_files ===1 || $order_detail->type_link_add_files === '1')-->
        <!--              <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
        <!--                                          {{$order_detail->location_files}}-->
        <!--                                              " onerror="imgError(this);">-->
        <!--              @else-->
        <!--              <br><br>-->
        <!--              <a href="{{$order_detail->location_files}}" target="_blank">ไฟล์เพิ่มเติม</a>-->
        <!--              <br> <br>-->
        <!--              @endif-->
        <!--            @else-->
        <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
        <!--                                        {{$order_detail->pattern_img}}-->
        <!--                                        " onerror="imgError(this);">-->
        <!--            @endif-->
        <!--            <br>-->
        <!--            <p class="card-text" style="font-size: 16px;font-weight: 700;color: black;">-->
        <!--              รุ่น: {{$order_detail->phone_model_name}}</p>-->
        <!--          </td>-->
        <!--        </tr>-->
        <!--        {{// &#45;&#45; $index > 12 && $index <= 18&#45;&#45;}}-->
        <!--        <tr style="width:100%">-->
        <!--          @foreach($print_envelope_order_details as $index =>  $order_detail)-->
        <!--          @if($index > 12 && $index <= 18 )-->
        <!--          <td style="text-align: center">-->
        <!--            @if($show === 'Y')-->
        <!--            @if($order_detail->location_files)-->
        <!--            @if($order_detail->type_link_add_files ===1 || $order_detail->type_link_add_files === '1')-->
        <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
        <!--                                        {{$order_detail->location_files}}-->
        <!--                                            " onerror="imgError(this);">-->
        <!--            @else-->
        <!--            <br><br>-->
        <!--            <a href="{{$order_detail->location_files}}" target="_blank">ไฟล์เพิ่มเติม</a>-->
        <!--            <br> <br>-->
        <!--            @endif-->
        <!--            @else-->
        <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
        <!--                                        {{$order_detail->pattern_img}}-->
        <!--                                        " onerror="imgError(this);">-->
        <!--            @endif-->
        <!--            <br>-->
        <!--            <p class="card-text" style="font-size: 16px;font-weight: 700;color: black;">-->
        <!--              รุ่น: {{$order_detail->phone_model_name}}</p>-->
        <!--            @endif-->
        <!--          </td>-->
        <!--          @endif-->
        <!---->
        <!--          @endforeach-->
        <!--        </tr>-->

        <!--        <tr style="width:100%">-->
        <!--          @foreach($print_envelope_order_details as $index =>  $order_detail)-->
        <!--          @if($index > 18 && $index <= 24 )-->
        <!--          <td style="text-align: center">-->
        <!--            @if($show === 'Y')-->
        <!--            @if($order_detail->location_files)-->
        <!--            @if($order_detail->type_link_add_files ===1 || $order_detail->type_link_add_files === '1')-->
        <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
        <!--                                        {{$order_detail->location_files}}-->
        <!--                                            " onerror="imgError(this);">-->
        <!--            @else-->
        <!--            <br><br>-->
        <!--            <a href="{{$order_detail->location_files}}" target="_blank">ไฟล์เพิ่มเติม</a>-->
        <!--            <br> <br>-->
        <!--            @endif-->
        <!--            @else-->
        <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
        <!--                                        {{$order_detail->pattern_img}}-->
        <!--                                        " onerror="imgError(this);">-->
        <!--            @endif-->
        <!--            <br>-->
        <!--            <p class="card-text" style="font-size: 16px;font-weight: 700;color: black;">-->
        <!--              รุ่น: {{$order_detail->phone_model_name}}</p>-->
        <!--            @endif-->

        <!--          </td>-->
        <!--          @endif-->

        <!--          @endforeach-->
        <!--        </tr>-->
        <!--        <tr style="width:100%">-->
        <!--          @foreach($print_envelope_order_details as $index =>  $order_detail)-->
        <!--          @if($index > 24 && $index <= 30 )-->
        <!--          <td style="text-align: center">-->
        <!--            @if($show === 'Y')-->
        <!--            @if($order_detail->location_files)-->
        <!--            @if($order_detail->type_link_add_files ===1 || $order_detail->type_link_add_files === '1')-->
        <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
        <!--                                        {{$order_detail->location_files}}-->
        <!--                                            ">-->
        <!--            @else-->
        <!--            <br><br>-->
        <!--            <a href="{{$order_detail->location_files}}" target="_blank">ไฟล์เพิ่มเติม</a>-->
        <!--            <br> <br>-->
        <!--            @endif-->
        <!--            @else-->
        <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
        <!--                                        {{$order_detail->pattern_img}}-->
        <!--                                        " onerror="imgError(this);">-->
        <!--            @endif-->
        <!--            <br>-->
        <!--            <p class="card-text" style="font-size: 16px;font-weight: 700;color: black;">-->
        <!--              รุ่น: {{$order_detail->phone_model_name}}</p>-->
        <!--            @endif-->

        <!--          </td>-->
        <!--          @endif-->

        <!--          @endforeach-->
        <!--        </tr>-->

        <!--        <tr style="width:100%">-->
        <!--          @foreach($print_envelope_order_details as $index =>  $order_detail)-->
        <!--          @if($index > 30 && $index <= 36 )-->
        <!--          <td style="text-align: center">-->
        <!--            @if($show === 'Y')-->
        <!--            @if($order_detail->location_files)-->
        <!--            @if($order_detail->type_link_add_files ===1 || $order_detail->type_link_add_files === '1')-->
        <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
        <!--                                        {{$order_detail->location_files}}-->
        <!--                                            " onerror="imgError(this);">-->
        <!--            @else-->
        <!--            <br><br>-->
        <!--            <a style="font-size: 16px;font-weight: 700;color: black;"-->
        <!--               href="{{$order_detail->location_files}}" target="_blank">ไฟล์เพิ่มเติม</a>-->
        <!--            <br> <br>-->
        <!--            @endif-->
        <!--            @else-->
        <!--            <img style="width: {{$width? :100}}px;height: {{$height? :100}}px;" src="-->
        <!--                                        {{$order_detail->pattern_img}}-->
        <!--                                        " onerror="imgError(this);">-->
        <!--            @endif-->
        <!--            <br>-->
        <!--            <p class="card-text" style="font-size: 16px;font-weight: 700;color: black;">-->
        <!--              รุ่น: {{$order_detail->phone_model_name}}</p>-->
        <!--            @endif-->

        <!--          </td>-->
        <!--          @endif-->

        <!--          @endforeach-->
        <!--        </tr>-->
      </table>
    </div>
  </div>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'

// import config from '../../../config'
// import instance from '../../../axios.service'
import Swal from 'sweetalert2'
import { email, minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import instance_order from '@/services/order'

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    type: true ? 'ใบสั่งซื้อสินค้า Purchase Order' : 'ใบสั่งซื้อสินค้า Purchase Order',
    payment_types: [],
    data: {
      supplier_name: '',
      employee_name: '',
      employee_id: '',
      purchase_on: '',
      tax_id: '',
      address: '',
      date: '',
      payment_id: 'เลือกการชำระเงิน',
      contact_name: '',
      project_name: '',
      sub_total: 0,
      discount: 0,
      total: 0,
      vat: 0,
      note: '',
      is_vat: true,

    },
    size: 100,
    order: {},
    purchase_order_details: [],
    configs: [],
    print_envelope_order_details: [],
    width: 100,
    height: 150,
    show: 'Y',
    amount_options: [
      1, 5, 10, 20, 50, 100, 500, 1000,
    ],
    amount: 1,
    selected: [],
    product_options: [],
    count: 0,
    product_select: 'ค้นหาสินค้า',
    purchase_order_type: 1, // ใบสั่งซื้อสินค้า Purchase Order 1    //
    length_for: 0,
    purchase_order_details_length: 7,
  }),
  validations() {
    return {
      editedItem: {
        name: { required },
        value: { required },
      },
    }
  },
  computed: {
    selectAll: {
      get() {
        return this.purchase_order_details ? this.selected.length == this.purchase_order_details.length : false
      },
      set(value) {
        const selected = []

        if (value) {
          Object.entries(this.purchase_order_details).forEach(([key, val]) => {
            if (val.product_id) {
              selected.push(parseInt(val.product_id))
            }
          })
        }

        this.selected = selected
      },
    },
    subTotal() {
      const total = []
      Object.entries(this.purchase_order_details).forEach(([key, val]) => {
        let sum = 0
        if (val.product_id) {
          const { product_costs } = val
          const { amount } = val
          const discount = parseInt(val.discount)
          if ((product_costs * amount) > discount) {
            sum = (product_costs * amount) - discount
          } else {
            sum = (product_costs * amount)
          }
        }

        total.push(parseFloat(sum).toFixed(2))
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    afterDiscount() {
      const { subTotal } = this
      const { discount } = this.data

      return parseFloat(subTotal - discount).toFixed(2)
    },
    VatTotal() {
      const { afterDiscount } = this
      const vat = (afterDiscount * 100) / 107

      return parseFloat(afterDiscount - vat).toFixed(2)
    },
  },

  watch: {},

  created() {

  },
  mounted() {
    this.data.id = this.$route.params.id
    this.purchase_order_type = this.$route.query.purchase_order_type
    this.show = this.$route.query.show
    this.size = this.$route.query.size
    this.height = this.$route.query.height
    this.getEnvelope()
  },
  methods: {
    getEnvelope() {
      const data = {
        order_id: this.data.id,
      }
      instance_order.getEnvelope(data).then(res => {
        this.configs = res.data.data.configs
        this.order = res.data.data.order
        this.configs = res.data.data.configs
        this.print_envelope_order_details = res.data.data.print_envelope_order_details
      }).catch(err => {

      })
    },
    amountLessThan20() {
      const data = {
        id: this.data_id,
        amount: this.amount,
        type: this.amount,
        purchase_order_type: this.purchase_order_type,
      }
      instance_purchase_order.show(data.id).then(res => {
        if (res && res.data) {
          this.data = res.data.data
          if (this.data.is_vat === 'Y') {
            this.data.is_vat = true
          } else {
            this.data.is_vat = false
          }
          this.purchase_order_details = res.data.purchase_order_details

          this.data.purchase_order_details = res.data.purchase_order_details
          if (this.data.purchase_order_details.length < 7) {
            this.length_for = (7 - this.data.purchase_order_details.length)
            this.purchase_order_details_length = this.data.purchase_order_details.length
          }
        }
      }).catch(err => {

      })

      // axios.get('{{url('admin/get_purchase_order').'/'.$data->id}}'+ '?amount=' + this.amount + '&type=' + this.type + '&purchase_order_type='+ this.purchase_order_type)
    },
  },
}
</script>

<style scoped>

.container

body{
  font-size: 11px;
}
@page {
  size: A4;
  margin: 0;
  font-size: 11pt;
}

. container

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
    font-size: 11pt;
  }

  img-print_envelope {
    width: 100px;
    height: 100px;
    font-size: 11pt;
  }

  /* ... the rest of the rules ... */
}
</style>
